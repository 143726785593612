@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@400;600;700;800&family=Roboto+Condensed:wght@300;400;700&display=swap');

/* HOW TO USE IS BELOW  */
/* font-family: 'Montserrat', sans-serif; */
/* font-family: 'Roboto Condensed', sans-serif; */

@import "bigSlider.css";
@import "button.css";
@import "card.css";

@import "footer.css";
@import "megaMenu.css";
@import "megaMenuMobile.css";
@import "navMenuMobile.css";
@import "navMenuDesktop.css";
@import "typo.css";
@import "responsive.css";
@import "MegaMenuAll.css";


body{
    background-color: white;
    max-width: 100%;
    width:100%;
}


.megaButtonBox{
    height: 300px;
    width: 300px;
    background-color: black;
    padding:16px;
    margin: 16px;;
}